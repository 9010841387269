/* Change this file to get your personal Portfolio */

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Hamdi Halimi",
  title: "Hi,I'm Hamdi",
  subTitle: (
    "As a dedicated  Full Stack Developer with over 5 years of experience, I have a proven track record in developing and implementing complex web applications using React.js, Node.js, and other modern technologies. My passion for problem-solving, coupled with my expertise in JavaScript and RESTful APIs, has led to a significant increase in user engagement and conversion rates in my previous roles. I am eager to leverage my skills in a challenging environment to create robust, scalable, and user-friendly applications"
  ),
  resumeLink:
  require("./assets/images/Hamdi-Halimi.pdf"),
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/hamdihalimi",
  linkedin: "https://www.linkedin.com/in/hamdi-halimi-a28801178/",
  gmail: "hamdihalimi93@gmail.com",
  gitlab: "https://gitlab.com/hamdihalimi",
  facebook: "https://www.facebook.com/hamdi.halimi/",
  instagram: 'https://www.instagram.com/hamdihalimi93/',
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "",
  skills: [
    emoji(
      "⚡ I have experience in developing various applications and projects, including using React.js to build user interfaces, Node.js and Express for backend development, and working with databases such as MongoDB . Additionally, I’ve built applications with advanced animations in JavaScript and worked with other technologies such as Socket.IO and MediaSoup."
    ),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "bootsrap",
      fontAwesomeClassname: "fab fa-bootstrap"
    },
  
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "Node Js",
      fontAwesomeClassname: "fab fa-node-js"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [

    {
      schoolName: "Faculty of Natural Sciences – UNIVERSITY OF TIRANA",
      logo: require("./assets/images/shkencatnatyres.png"),
      subHeader: "Bachelor of Mathematics and Computing / Engineering",
      duration: "September 2012 - April 2017",
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "HTML5/ CSS3", //Insert stack or technology you have experience in
      progressPercentage: "100%" //Insert relative proficiency in percentage
    },
    {
      Stack: "JavaScript/ jQuery", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "React Js/Redux ", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    
    {
      Stack: "Node Js/Express Js ", //Insert stack or technology you have experience in
      progressPercentage: "50%" //Insert relative proficiency in percentage
    },

  ],
  displayCodersrank: true // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Full Stack Developer",
      company: "Freelancer",
      companylogo: require("./assets/images/freelancer.jpg"),
      date: "1 Jun 2023 – Present",
      desc:
        "Building different platforms according to the client's request.",
        descBullets: [

          "Designing and developing customized web applications for clients using React.js, Node.js, and MongoDB.",
          "Implementing real-time features with Socket.IO and MediaSoup for interactive applications.",
          "Creating scalable backend architectures with Express and integrating modern frontend frameworks for responsive and user-friendly designs.",
          "Managing diverse projects, including video conferencing platforms, 2D games, and NFT-related applications.",
        ]
    },
    {
      role: "Full Stack Developer",
      company: "TrioKos",
      companylogo: require("./assets/images/Trio-Kos-Logo.png"),
      date: "July 2022 – May 2023",
      desc:
        "Honoring a communication platform like google meet, and building a 2d game for nft.",
        descBullets: [

          "Built a Google Meet-like application using React.js, Node.js, Express, and MongoDB for real-time video communication.",
          "Integrated MediaSoup for low-latency media streaming and Socket.IO for seamless WebSocket-based interactions.",
          "Designed a responsive UI with React, ensuring a user-friendly experience across devices..",
          "Focused on scalability and performance, handling concurrent user connections efficiently.",
          "Developed a 2D combat game for an NFT collection using React.js and Node.js..",
          "Implemented interactive mechanics and animations, creating a unique gaming experience for NFT holders.",
          "Optimized the game logic for smooth performance, focusing on scalability and seamless user interaction.",
        ]
    },
    {
      role: "Front End Developer",
      company: "Kosbit",
      companylogo: require("./assets/images/kosbit.png"),
      date: "July 2021 – February 2022",
      desc:
        "Creation of a platform for employee maintenance",
        descBullets: [

          "Developed a React.js-based application for managing and maintaining data records..",
          "Integrated backend APIs using Postman to test endpoints and ensure smooth data retrieval and submission.",
          "Designed a user-friendly interface with responsive layouts and custom interactive features..",
          "Employed React hooks and context for state management, ensuring application scalability and performance optimization..",
        ]
    },
    {
      role: "Frontend Developer",
      company: "Karrota",
      companylogo: require("./assets/images/Karrota.jpg"),
      date: "November 2019 – June 2021",
      desc:
        "Design, develop and relaunch responsive websites and e-commerce sites of clients.",
        descBullets: [
         "Developed dynamic and interactive user interfaces using HTML, CSS, and JavaScript..", 
         "Created custom animations and transitions with JavaScript to enhance user 	engagement and deliver smooth interactions.",
         "Utilized Bootstrap to ensure responsive and consistent designs across multiple devices.",
         "Collaborated with designers and backend developers to integrate complex animationsand ensure functionality alignment.",
        ]
    },
    {
      role: "Frontend Developer",
      company: "Smart Point",
      companylogo: require("./assets/images/smartpoint.png"),
      date: "Mar 2019 – Octember 2019",
      desc:
        "Build reusable code and libraries for future use.",
      descBullets: [
        "Designed and developed a fully responsive portfolio website using HTML, CSS, and JavaScript.", 
        "Implemented smooth scrolling, hover effects, and custom animations with JavaScript to enhance the visual appeal.", 
        "Design, develop and relaunch responsive websites and e-commerce sites of clients. Ensure the technical feasibility of UI/UX designs.",
        "Used Bootstrap to streamline layout creation and ensure compatibility across devices.", 
        "Focused on optimizing animations for better performance and user experience.", 
      ]
    },
    {
      role: "Frontend Developer",
      company: "PreshevaWeb",
      companylogo: require("./assets/images/preshevaweb.png"),
      date: "Dec 2018 – Feb 2019",
      desc:
        "Build reusable code and libraries for future use.",
      descBullets: [
        "Built a fully responsive e-commerce website using HTML5, CSS3, and JavaScript.", 
        "Utilized Bootstrap components to create a modern and user-friendly design.", 
        "Implemented dynamic features like interactive product filters and modals for an enhanced shopping experience.",
        "Ensured compatibility across different browsers and devices.", 
      ]
    },
  
   
   
    
    
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "hamdihalimi", // Change to your github username to view your profile in Contact Section.
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url:
            ""
        },
        {
          name: "Award Letter",
          url:
            ""
        },
        {
          name: "Google Code-in Blog",
          url:
            ""
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      footerLink: [
        {
          name: "View Google Assistant Action",
          url:
            ""
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: ""
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff.",

  blogs: [
    {
      url:
        "https://karrota.wtf/",
      title: "Karrota creative agency . ",
      description:
        "Leading creative agency in Kosova and Albania. Cannes Gold Lion Award winners. Creators of NEWBORN Monument."
    },
    {
      url: "https://cms-direktwerbung.de/",
      title: "CMS-DIREKTWERBUNG ",
      description:
        "We are your distributor in the Rhine-Main area and we are also responsible for your direct marketing throughout Germany, inexpensive, flexible and on time with our own control system. Test us and request a non-binding offer. Our quality management and comprehensive reporting give you a high level of security for a successful direct delivery.."
    },
    {
      url: "https://sca-dienstleistung.de/",
      title: "SCA DIENSTLEISTUNG ",
      description:
        "Wir übernehmen für Sie als Privat- oder Geschäftskunde die Demontage und fachgerechte Entsorgung von Asbestzementprodukten und schwach gebundenen Asbestprodukten nach Gefahrenstoffverordnung TRGS 519.."
    },
  
    {
      url: "https://www.triokos.com/",
      title: "TrioKOS",
      description:
        "TrioKos is a leading software development company based in Prishtina (Kosovo). We are a team of dedicated and passionate individuals who share a common goal: to create innovative software solutions that make a difference in people's lives,Since 2016, we specialize in providing innovative and customized software solutions to clients across the globe. Our areas of expertise include custom software development, website and mobile development, e-commerce, UX/UI design, dedicated teams, and tailored software solutions."
    },
    {
      url: "https://triocall.triokos.com/",
      title: "Trio Call",
      description:
        "Trio Call is a chat is an online face-to-face, visual communication performed with other Internet users by using a webcam and dedicated software. ."
    },
    {
      url: "https://game.peacockwarriors.com/",
      title: "Peacockwarriors Game",
      description:
        "Peacockwarriors Game is a game  2D , designed for challenges between customers owning a Peacockwarriors NFT, whoever has the most wins in a month wins the specified prize."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: (
    ""
  ),

  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: ""
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    ""
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: ("Contact Me"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "(+383)49 226 870",
  email_address: "hamdihalimi93@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "hamdihalimi", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
